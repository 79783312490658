/* eslint-disable no-magic-numbers */
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, skip, take } from 'rxjs/operators';


interface AsyncDataCacheEntry<T = any> {
  timestamp: number;
  key: string;
  value: BehaviorSubject<T>;
}

interface SerializableAsyncDataCacheEntry<T = any> extends Omit<AsyncDataCacheEntry<T>, 'value'> {
  value: T;
}

/**
 * Type that combines the source observable and factory, which can returns the source observable
*/
type AsyncDataCacheValueSource<T = any> = Observable<T> | (() => Observable<T>);

export interface AsyncDataCacheValueSourceObject<T = any> {
  source: AsyncDataCacheValueSource<T>;
  refreshWhen?: Observable<any>;
}

export interface AsyncDataCacheOptions<T = any> {
  maxAge?: number;
  dataSerializable?: boolean;
  environmentArgs?: {key: string; value: string;}[];
  sources?: {key: string; object: AsyncDataCacheValueSourceObject<T>}[];
}

export class AsyncDataCache<T = any> {

  static create<T = any>(object: AsyncDataCacheValueSourceObject<T>, uniqueCacheName?: string, maxAge?: number): AsyncDataCache<T> {
    const name = uniqueCacheName || ('' + Date.now() + Math.random()); // NOSONAR
    const tmp = new AsyncDataCache(name, {
      dataSerializable: !!uniqueCacheName,
      maxAge: maxAge ?? (1000 * 60 * 60 * 4), // NOSONR
      sources: [{key: 'infer', object}]
    });
    return tmp;
  }

  private _storageKeyPrefix = '__AsyncDataCache__';

  private _cache = new Map<string, AsyncDataCacheEntry<T>>();
  private _pending = new Set<string>();
  private _sources = new Map<string, AsyncDataCacheValueSourceObject<T>>();
  private _reestablishCacheOnEnvironmentChange = true;

  private _storage = localStorage;

  private getStorageKey(): string {
    const args = Array.from(this.environmentMap.entries());
    args.sort((arga, argb) => {
      if (arga[0] > argb[0]) {
        return 1;
      }
      if (arga[0] < argb[0]) {
        return -1;
      }
      return 0;
    });
    const postfix = args.length ? ('?' + args.map(arg => arg[0] + '=' + arg[1]).join('&')) : '';
    return this._storageKeyPrefix + postfix;
  }

  private environmentMap = new Map<string, string>();

  maxAge: number;
  dataSerializable: boolean;
  environmentArgs?: {key: string, value: string}[];

  private visibilitychangeHandler = (e: Event) => {
    if (document.hidden) {
      this.writeCacheToStorage();
    }
  };

  constructor(public uniqueCacheName: string, options?: AsyncDataCacheOptions<T>) {

    options = options || {};
    this.dataSerializable = options.dataSerializable;
    this.environmentArgs = options.environmentArgs || [];
    this.maxAge = options.maxAge || 0;
    options.sources?.forEach(pair => {
      this.addSource(pair.key, pair.object);
    });

    this._storageKeyPrefix += encodeURIComponent(uniqueCacheName);
    this._reestablishCacheOnEnvironmentChange = false;
    this.addEnvironmentArgs(this.environmentArgs, true);
    this._reestablishCacheOnEnvironmentChange = true;

    if (this.dataSerializable && window?.document?.addEventListener && localStorage && sessionStorage) {
      this.readCacheFromStorage();

      // https://developers.google.com/web/updates/2018/07/page-lifecycle-api#the-unload-event
      document.addEventListener('visibilitychange', this.visibilitychangeHandler);
    }
  }

  addEnvironmentArg(key: string, value: string, init?: boolean) {
    const args = [{key, value}];
    this.addEnvironmentArgs(args, init);
  }

  addEnvironmentArgs(args: {key: string, value: string}[], init?: boolean) {
    const key = this.getStorageKey();

    args?.forEach(arg => {
      this.environmentMap.set(arg.key, arg.value);
    });

    if (!init && this._reestablishCacheOnEnvironmentChange && key !== this.getStorageKey()) {
      this.clear({cache: true, pendingRequests: true});
      if (this.dataSerializable) {
        this.readCacheFromStorage();
      }
    }
  }

  clear(args: {cache?: boolean; pendingRequests?: boolean; sources?: boolean; serializedData?: boolean}) {

    if (args.cache) {
      this._cache.clear();
    }

    if (args.pendingRequests) {
      this._pending.clear();
    }

    if (args.sources) {
      this.unsubscribeFromAllRefreshSourceObservable();
      this._sources.clear();
    }

    if (args.serializedData) {
      const sKey = this.getStorageKey();
      this._storage.removeItem(sKey);
    }
  }

  /**
   * writes cache to storage if data is serializable, removes necessary event listener and unsubscribes from all subscribed refresh source observables
   */
  complete() {
    if (this.dataSerializable && window?.document?.addEventListener && localStorage && sessionStorage) {
      this.writeCacheToStorage();
    }
    this.unsubscribeFromAllRefreshSourceObservable();
    document.removeEventListener('visibilitychange', this.visibilitychangeHandler);
  }

  /**
   * returns if all given environment key-value pairs exists in the environment and have the also given value
   */
  hasAllEnvironmentArgs(args?: {key: string; value: string;}[]): boolean {
    let allIn = true;
    args.forEach(arg => {
      if (allIn && this.environmentMap.get(arg.key) !== arg.value) {
        allIn = false;
      }
    });
    return allIn;
    // return !args.some(arg => (this.environmentMap.get(arg.key) === arg.value));
  }

  /**
   * adds a value to a key, which is most often the response of an external request or source
   * @param key - a string to uniquely identify an expected response
   * @param value - the response (data, which needs to be cached)
   */
  addResponse(key: string | null, value: T): AsyncDataCache<T> {

    key ||= this.tryInferKey();

    const now = Date.now();

    const entry = this._cache.get(key);
    if (entry) {
      entry.timestamp = now;
      entry.value.next(value);
    } else {
      this._cache.set(key, {key, timestamp: now, value: new BehaviorSubject(value)});
    }

    return this;
  }

  addSource(key: string | null, object: AsyncDataCacheValueSourceObject<T>): AsyncDataCache<T> {
    key ||= this.tryInferKey();

    this.unsubscribeRefreshSourceObservable(key);

    this._sources.set(key, object);

    if (object.refreshWhen) {
      this.subscribeRefreshSourceObservable(key, object);
    }

    return this;
  }

  hasSource(key?: string | null): boolean {
    key ||= this.tryInferKey();
    return !!this._sources.get(key);
  }

  private subscribeRefreshSourceObservable(key: string, object: AsyncDataCacheValueSourceObject<T>) {
    if (object.refreshWhen) {
      object['__subscriptionWhen'] = object.refreshWhen.subscribe(() => {
        this.refreshResponseFromSource(key);
      });
    }
  }

  private unsubscribeRefreshSourceObservable(key: string) {
    const oldSource = this._sources.get(key);
    (oldSource?.['__subscriptionWhen'] as Subscription)?.unsubscribe();
  }

  private unsubscribeFromAllRefreshSourceObservable() {
    Array.from(this._sources.keys()).forEach(key => {
      this.unsubscribeRefreshSourceObservable(key);
    });
  }

  /**
   * requests the source without checking the cache and the max age
   * Note: heavy use can cause unnecessary requests
   */
  private getResponseFromSource(key: string | null, object: AsyncDataCacheValueSourceObject<T>, oneTimeOnly?: boolean): Observable<T> {
    key ||= this.tryInferKey();

    if (!oneTimeOnly) {
      this.addSource(key, object);
    }

    if (!this._pending.has(key)) {
      this._pending.add(key);
      let source = object.source;
      if (typeof source === 'function') {
        source = source();
      }
      source.pipe(
        take(1),
      ).subscribe(value => {
        this._pending.delete(key);
        this.addResponse(key, value);
      });

    }

    return this.getResponse(key);
  }

  /**
   * whether resource exists AND is still valid to use
   * @param key - a string to uniquely identify an expected response
   */
  doesSuitableResponseExist(key?: string): boolean {
    key ||= this.tryInferKey();
    return this._cache.has(key) && !this.isCachedEntryTooOld(this._cache.get(key));
  }


  /**
   * gets resource of the cache
   * @param key  - a string to uniquely identify an expected response
   */
  getValueSync(key?: string): T {
    key ||= this.tryInferKey();
    return this._cache.get(key)?.value?.value;
  }

  /**
   * returns an observable, which delivers the value syncroniously if the value is not too old.
   * If it is too old or has not been requested before it will requested if there is a given source for that key.
   */
  getResponse(key?: string): Observable<T> {

    key ||= this.tryInferKey();

    let entry = this._cache.get(key);
    let newEntry = false;

    if (!entry) {
      entry = {key, timestamp: Date.now(), value: new BehaviorSubject(void 0)};
      this._cache.set(key, entry);
      newEntry = true;
    }

    // returns true if the entry is too old
    const tooOld = this.isCachedEntryTooOld(entry);

    if (tooOld || newEntry) {
      // if it is not a new entry, add void because data is too old and therefore unreliable
      if (tooOld) {
        this.addResponse(key, void 0);
      }
      this.refreshResponseFromSource(key);
    }

    const pending = this._pending.has(key);
    return entry.value.pipe(
      skip((pending ? 1 : 0))
    );
  }

  refreshResponseFromSource(key: string) {
    key ||= this.tryInferKey();
    const source = this._sources.get(key);
    if (source) {
      this.getResponseFromSource(key, source, true);
    }
  }

  refreshAllResponsesFromSource() {
    Array.from(this._cache.keys()).forEach(key => {
      this.refreshResponseFromSource(key);
    });
  }

  private isCachedEntryTooOld(entry: AsyncDataCacheEntry<T>): boolean {
    return this.maxAge !== 0 ? (!entry || !entry.timestamp || !((entry.timestamp + this.maxAge) > Date.now())) : false;
  }

  private writeCacheToStorage() {

    const arr = Array.from(this._cache.values())
    .filter(entry => !this.isCachedEntryTooOld(entry))
    .map<SerializableAsyncDataCacheEntry>(entry => ({key: entry.key, timestamp: entry.timestamp, value: entry.value?.value}));

    const serialized = JSON.stringify(arr);
    this._storage.setItem(this.getStorageKey(), serialized);
  }

  private readCacheFromStorage() {

    const serialized = this._storage.getItem(this.getStorageKey());
    if (serialized) {
      const deserialized = (JSON.parse(serialized) as SerializableAsyncDataCacheEntry<T>[]) || [];

      deserialized
      .map<AsyncDataCacheEntry>(entry => ({key: entry.key, timestamp: entry.timestamp, value: new BehaviorSubject(entry.value)}))
      .filter(entry => !this.isCachedEntryTooOld(entry)).forEach(entry => {
        const oldBs = this._cache.get(entry.key)?.value;
        if (oldBs) {
          entry.value = oldBs;
        }
        this._cache.set(entry.key, {key: entry.key, timestamp: entry.timestamp, value: entry.value});
        if (oldBs) {
          oldBs.next(entry.value.value);
        }
      });
    }
  }

  private tryInferKey(): string {
    const keys = Array.from(this._cache.keys());
    if (keys.length === 1) {
      return keys[0];
    }
    if (keys.length === 0) {
      const sourceKeys = Array.from(this._sources.keys());
      if (sourceKeys.length === 1) {
        return sourceKeys[0];
      }
    }

    throw new Error(`Cannot infer key of ${this.uniqueCacheName} AsyncDataCache because it is ambiguouse of what key to use.`);

  }

}
